:root {
  --hover-colour: #ff2626;
  --delete-colour: #e61c1c;
  --button-colour: #000;
  --hover-colour-mono: #333;
  --app-height: 100%;
}

.App {
  position: relative;
  min-height: 100dvh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-text-colour {
  color: #e0e0e0 !important;
}

.nav-text-colour :hover {
  color: #fff !important;
}

.login-form-title {
  text-align: center;
  font-weight: 700;
}

.login-form-box {
  width: 400px;
  margin-top: 80px;
}

.login-submit-button {
  background-color: var(--button-colour);
  border: none;
  font-weight: 600;
}

.login-submit-button:hover {
  background-color: var(--hover-colour);
  border: none;
}

.login-submit-button:focus {
  background-color: var(--hover-colour);
  border: none;
}

.login-submit-button:active {
  background-color: var(--hover-colour) !important;
  border: none;
}

.forgot-password-text {
  color: var(--hover-colour-mono);
  font-weight: 500;
  text-decoration: underline;
  text-decoration-color: var(--hover-colour-mono);
  -webkit-text-decoration-color: var(--hover-colour-mono);
}

.forgot-password-text:hover {
  color: var(--hover-colour);

  text-decoration: underline;
  text-decoration-color: var(--hover-colour);
  -webkit-text-decoration-color: var(--hover-colour);
}

.content-wrap {
  padding-bottom: 2.5rem; /* Footer height */
}

.footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem; /* Footer height */
}

.page-margin {
  margin-top: 2%;
}

.page-title {
  font-weight: 700;
  font-size: xx-large;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.text-link:hover {
  color: inherit;
  text-decoration: inherit;
}

.tour-box-title {
  font-weight: 600;
  font-size: large;
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.tour-box-text {
  font-weight: 400;
  font-size: medium;
  margin-left: 15px;
  margin-bottom: 5px;
  margin-right: 10px;
  padding: 0 !important;
}

.tour-box-date {
  font-weight: 400;
  font-size: small;
  margin-left: 15px;
  margin-bottom: 5px;
  margin-right: 10px;
  padding: 0 !important;
}

.section-header {
  font-weight: 700;
  font-size: large;
  margin-bottom: 5px;
}

.subsection-header {
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: medium;
}

.section-text {
  font-weight: 400;
  margin-bottom: 5px;
  font-size: medium;
}

.white-icon {
  color: #fff;
}

.navbar-toggler {
  border: #000;
}

.navbar-toggler:focus {
  color: #fff;
  box-shadow: 0 0 0 2px;
}

.navbar-toggler:hover {
  color: #fff !important;
  box-shadow: 0 0 0 2px;
}

.navigation-item {
  text-decoration: none;
  color: #fff !important;
  font-size: large;
  font-weight: 600;
}

.navigation-item:hover {
  text-decoration: none;
  color: #fff !important;
  text-decoration: underline;
}

.navigation-item:focus {
  text-decoration: none;
  color: #fff !important;
  text-decoration: underline;
}

.client-box {
  margin: 5px;
}

.client-box-company {
  font-size: larger;
  font-weight: 700;
}

.delete-button {
  background-color: var(--hover-colour);
  border: none;
  font-weight: 600;
}

.delete-button:hover {
  background-color: var(--delete-colour);
  border: none;
}

.delete-button:focus {
  background-color: var(--delete-colour);
  border: none;
}

.delete:active {
  background-color: var(--delete-colour) !important;
  border: none;
}

.grow:hover {
  transform: scale(1.015);
}
.grow {
  cursor: pointer;
}

.mouse-click {
  cursor: pointer;
}

.icon-plain {
  color: #333;
}

.icon-clicked {
  color: #407af7;
}

.editable-box {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

.editable-box:focus {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid var(--hover-colour);
  border-radius: 0;
}

.tab-bar {
  color: #000 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 3px solid var(--hover-colour);
}

.nav-tabs {
  color: #333;
}

.nav-link {
  color: #333;
  font-weight: 700;
}

.nav-link:hover {
  font-weight: 700;
  color: var(--hover-colour);
}

.nav-link.active {
  font-weight: 700;
  color: var(--hover-colour) !important;
}

@media only screen and (max-width: 600px) {
  .content {
    margin-top: 10px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}

.spinner-colour {
  color: var(--hover-colour);
}

.selected-tour {
  border-bottom: 5px solid var(--hover-colour) !important;
}

.grow-2:hover {
  transform: scale(1.1);
}
.grow-2 {
  cursor: pointer;
}

.file-upload {
  display: block;
  position: relative;
  cursor: pointer;
}

.file-upload:hover .space-image {
  opacity: 0.7;
}

.file-upload:hover .file-upload-content {
  opacity: 1;
}

.file-upload-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--hover-colour-mono);
  font-size: 50px;
  opacity: 0;
}

.file-upload-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--hover-colour-mono);
  font-size: 50px;
}

.placeholder-element {
  color: #333;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: larger;
  font-weight: 600;
}

.add-on-title {
  color: #333;
  text-align: center;
  margin-bottom: 15px;
  font-size: x-large;
  font-weight: 600;
}

.add-on-icon {
  padding: 10;
  margin-bottom: 10px;
  max-width: 50;
  max-height: 50px;
}

.add-on-card {
  margin-top: 10px;
  padding: 10px;
  border-bottom: 2px solid var(--hover-colour);
  height: 200px;
}

.red {
  background-color: red !important;
}

.forgot-password-description {
  text-align: center;
  margin-top: 29px;
  margin-bottom: 25px;
}

.branded-tour-iframe {
  display: block; /* iframes are inline by default */
  height: 100dvh; /* Set height to 100% of the viewport height */
  width: 100vw; /* Set width to 100% of the viewport width */
  border: none;
  position: relative;
}

.branded-tour-brand {
  position: absolute;
  top: 20px;
  right: 15px;
}

.fullscreen {
  margin-left: 10px;
  color: white;
  font-size: xx-large;
  background-color: rgba(44, 40, 40, 0.7);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.fullscreen:hover {
  background: rgba(79, 74, 74, 0.7);
  color: rgba(255, 255, 255, 0.7);
}

.tour-navbar {
  background-color: #e61c1c;
  width: 100px;
  height: 100px;
}

.psv-navbar {
  background-color: rgba(255, 255, 255, 0) !important;
}

.navbar-button {
  color: white;
  font-size: xx-large;
  background: rgba(44, 40, 40, 0.7) !important;
  padding: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}

.navbar-button:hover {
  background: rgba(79, 74, 74, 0.7) !important;
  color: rgba(255, 255, 255, 0.7);
}

.navbar-button span {
  color: white;
  font-size: xx-large;
}

.navbar-fullscreen {
}

.psv-gallery-button {
  color: white;
  font-size: xx-large;
  background: rgba(44, 40, 40, 0.7) !important;
  margin-left: 10px;
  margin-right: 0px;
  margin-top: -20px;
  min-width: 30px;
  min-height: 30px;
  width: 80% !important;
  display: flex;
  align-items: center;
  border-radius: 5px;
  align-content: center;
  transform: scale(1) !important;
}

.psv-gallery-button:hover {
  background: rgba(79, 74, 74, 0.7) !important;
  color: rgba(255, 255, 255, 0.7);
  transform: scale(1) !important;
}

.psv-gallery-button svg:hover {
  transform: scale(1) !important;
}

.psv-gallery-button svg {
  transform: scale(1) !important;
  width: 30px;
}

.psv-gallery--open {
  bottom: 70px !important;
  border: none !important;
}

.tour-container {
  width: 70%;
  display: block;
}

.custom-navbar {
  position: absolute;
  /* background: rgba(44, 40, 40, 0.7) !important; */
  height: 70px;
  width: 70%;
  bottom: 0px;
  left: 0px;
  z-index: 90;
}

.custom-gallery-button {
  color: white;
  font-weight: 600;
  text-align: center;
  font-size: large;
  background: rgba(44, 40, 40, 0.7) !important;
  height: 50px;
  padding: 10px;
  width: 90% !important;
  border-radius: 5px;
}

.custom-gallery-button:hover {
  background: rgba(79, 74, 74, 0.7) !important;
  color: rgba(255, 255, 255, 0.7);
}
